import RestApiClient from '@/api-clients/rest-api-client';

import type { Request, Response } from 'express';
import type { AbTestSettings, AbTestGroup } from '@/types/ab-tests';

let abSettings: AbTestSettings;

export const getAbSettings = async () => {
  if (abSettings) {
    return abSettings;
  }

  try {
    const restApi = new RestApiClient();

    abSettings = await restApi.getAbSettings();
  } catch {
    abSettings = {};
  }

  return abSettings;
};

export const getGroupNameFromCookie = (
  randomValue: number, // Случайное значение из куки
  groups: Record<string, AbTestGroup>, // Объект с группами и их весами
): string => {
  if (typeof randomValue !== 'number' || Number.isNaN(randomValue)) {
    return ''; // Возвращаем пустую строку, если randomValue не валиден
  }

  const groupKeys = Object.keys(groups);

  // Вычисляем общую сумму весов
  const totalWeight = groupKeys.reduce((sum, key) => sum + groups[key].weight, 0);

  // Нормализуем веса и ищем соответствующую группу
  let cumulativeWeight = 0;

  for (const key of groupKeys) {
    cumulativeWeight += groups[key].weight / totalWeight; // Вес группы как доля от общего веса

    if (randomValue < cumulativeWeight) {
      return key; // Возвращаем название группы
    }
  }

  // Если по какой-то причине не найдено, возвращаем пустую строку
  return '';
};

export const initTest = (
  req: Request,
  res: Response,
  name: string,
  groups: Record<string, AbTestGroup>,
) => {
  let randomValue = parseFloat(req.cookies[name]);

  // Если в куке нет случайного значения, генерируем его
  if (Number.isNaN(randomValue)) {
    randomValue = Math.random();
    res.cookie(name, randomValue.toString(), { maxAge: 365 * 24 * 60 * 60 * 1000 });
  }

  // Получаем название группы на основе сохраненного значения
  const selectedGroup = getGroupNameFromCookie(randomValue, groups);

  // Возвращаем путь выбранной группы
  return groups[selectedGroup]?.path;
};
